<template >
    <section class="list" id="miao">
        <loading v-if="isLoading"></loading>
        <div class="tit"><a href="/">首页</a> > {{ className }}

            <span></span>
            <hr>
        </div>


        <div class="items" v-if="Listdata.isSuccess">


            <figure :id="zhibo" v-for="p in Listdata.info" :key="p.ID">
                <div class="pos">
                    <router-link target="_blank" :to="{
                            name: 'M5Detail',
                            params: {
                                cid: p.classid,
                                id: p.id,



                            }
                        }" :title="p.title">
                        <img :src="p.titlepic" :key="p.id" />
                        <p><span>{{ p.newstime }}</span></p>

                    </router-link>
                </div>
                <router-link target="_blank" :to="{
                    name: 'M5Detail',
                    params: {
                        cid: p.classid,
                        id: p.id,


                    }
                }" :title="p.title">
                    <figcaption>
                        {{ p.title }}
                    </figcaption>
                </router-link>
            </figure>

        </div>
        <div class="items" v-if="Listdata.isSuccess == false">
            <h3>数据正在加载，请稍后！</h3>
        </div>
        <div class="pageDiv" v-if="Listdata.isSuccess == true">
            <ul class="paginationU">

                <li class="page-item" ><router-link class="page-link" :to="{
                    name:'M5Category',
                     params:{
                        cid:routeInfo.cid,
                        page:prePage
                    }
                }">◀</router-link></li>
        
             <li class="page-item" :class="{active: curPage == Number(number)+1 }"  v-for="(number,index) of ListItem" :key="index">
            <router-link class="page-link" :to="{
                    name:'M5Category',
                     params:{
                        cid:routeInfo.cid,
                        page:Number(number)+1
                    }
                }">{{Number(number)+1}}</router-link></li>

         <li class="page-item"><router-link class="page-link" :to="{
                    name:'M5Category',
       
                    params:{
                        cid:routeInfo.cid,
                        page:nextPage
                    }
                }">▶</router-link></li>


            </ul>

        </div>

    </section>
</template>
<script>
import apis from "@/utils/crypt"
import loading from "../components/M5Loading"
export default {
    name: "M5Category",
    components: { loading },


    data() {
        return {
            Listdata: {
                isSuccess: false,
                info: {},
            },
            className: "",
            routeInfo: {
                cid: "",
                page: ""
            },
            isLoading: true,
            curPage: 1,
            total: 0,
            pageCount: 0,
            pageList: [],





        }
    },

    computed: {


        prePage() {
            if (this.curPage != 1) {
                return this.curPage - 1;
            } else {
                return 1
            }
        },



        nextPage() {
            if (this.curPage != this.pageCount) {
                return this.curPage + 1;
            } else {
                return this.pageCount
            }
        },
        ListItem() {

            let start
            let end
            if (this.curPage < 6) {
                start = 0
                end = 7
            } else if (this.curPage == this.pageCount) {
                start = this.curPage - 7
                end = this.curPage
            } else {
                start = this.curPage - 3
                end = this.curPage + 4
            }



            return this.pageList.slice(start, end)
        },
        zhibo() {
            if (this.routeInfo.cid == 68) {
                return "zhibo"
            } else {
                return false
            }
        }



    },


    methods: {
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            if (flag) {
                return false
            } else {
                return true
            }
        },
        getListInfo(cid, page) {
            this.isLoading = true
            this.$ajax.post(this.Url.ListAddr, {
                c: apis.rsaEncode(cid.toString()),
                p: apis.rsaEncode(page.toString()),

            }).then(
                response => {
                    try {
                       
                        this.Listdata.info = response.data
                        
                        this.curPage = response.data.PageNo
                        this.total = response.data.TotalRecord
                        this.pageCount = response.data.TotalPageNo
                        this.Listdata.isSuccess = true
                        this.routeInfo.cid = this.$route.params.cid
                        this.routeInfo.page = this.$route.params.page
                        this.className = response.data.ClassName
                        document.title = this.className
                        this.pageList = Object.keys(Array.from(Array(this.pageCount)))
                        this.isLoading = false
                    }
                    catch {
                        this.$router.push("/404")
                    }

                })

        },
        page(indexPage) {
            this.curPage = indexPage;
        }
        ,



    },

    beforeRouteUpdate(to, from, next) {

        this.getListInfo(to.params.cid, to.params.page)
        next()
    },

    mounted() {
        this.getListInfo(this.$route.params.cid, this.$route.params.page)






    },





}
</script>
