<template>
<div class="loading"></div>
</template>
 
<script>
export default {
  name: 'Loading' // 定义的组件名称 使用时写法：loading
}
</script>
 
<style scoped>
.loading {
  position: fixed;
  left: 0;
  top: 0;
  background: url('~@/assets/loading-ball.svg') center center no-repeat rgb(34, 34, 34);
  width: 100%;
  height: 100%;
  z-index: 1000;
}
</style>
